import React, {useState} from 'react';
import {graphql} from "gatsby"
import {withStyles} from '@material-ui/core/styles';
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import LayoutInner from "../../components/layoutInner";
import {keys} from "lodash"
import * as PATHS from "../../utils/paths";
import "../../utils/index"
import ContactForm from '../../components/ContactForm'
import { isValidEmailAddress } from '../../utils'

const styles = theme => ({
    root: {},
    heroText: {
        fontSize: 32,
        lineHeight: "48px",
        fontWeight: 400,
        marginBottom: 16
    },
    subheroText: {
        fontSize: 18,
        lineHeight: "32px",
        fontWeight: "regular",
        marginBottom: 16
    },
});




const _Page = ({data, location, classes}) => {
    const siteTitle = data.site.siteMetadata.title
    const [submitSuccess, setSubmitSuccess] = useState(false)

    return (
        <Layout location={location} title={siteTitle} hideNewsletterForm={true}>
            <SEO title={"Workshop: Visualizing People"} location={{pathname: PATHS.WORKSHOPS_PEOPLE_DATAVIZ}}/>

            <LayoutInner>

                <h1>People Visualization Workshop</h1>
                <img style={{maxWidth: "100%"}} src={"https://3iap.com/cdn/images/3iap-people-visualization-crowd-v2.png"}/>
                <br/><br/>


                <h3>Workshop: How might we effectively visualize people? </h3>

                <p>Whether it's program evaluation, people analytics, or presenting social science research,
                    some of the most important analysis involves characterizing people and their outcomes.
                    This typically involves questions like:
                </p>
                <ul>
                    <li>Composition: Who are these people?</li>
                    <li>Representation: How similar is this group to the wider population?</li>
                    <li>Contrasts: How does one group differ from another?</li>
                    <li>Outcomes and Activity: What are they doing? How might we help?</li>
                    <li>Trends: What has changed over time?</li>
                </ul>

                <p>
                    For each of these questions, there are common sets of analyses and visualizations for effectively
                    analysis to uncover answers, and effective visualization to communicate the results.
                    In this workshop we'll cover these basic techniques for analyzing and visualizing people.
                </p>

                {/*(e.g. preparing the data, analysis techniques, showing changes over time, within-group distributions, group comparisons, showing demographic representation, etc)*/}


                <p>These workshops help chart makers:</p>
                <ol>
                    <li>Understand the three foundational chart types essential for visualizing people and their outcomes.</li>
                    <li>Learn new techniques for handling common pitfalls when preparing and analyzing people data.</li>
                    <li>Develop intuition for the surprising ways that social psychology can influence viewers’ perceptions of others.</li>
                </ol>

                <p>The workshops are for any groups interested in designing more humanistic data visualizations, but they're especially suited for:</p>
                <ul>
                    <li>Social program evaluation experts</li>
                    <li>People analytics teams</li>
                    <li>Advocacy groups using data for social impact</li>
                    <li>Public-health communicators reporting health outcomes</li>
                    <li>Government agencies or non-government organizations sharing statistics about their constituents</li>
                </ul>

                <p>If this sounds like your organization, then please get in touch. You can reach us in one of the following ways:</p>

                <ul>
                    <li>Email <a href={"mailto:hi+equityworkshop@3iap.com"}>hi+equityworkshop@3iap.com</a><br/></li>
                    <li>Book a time on <a href={"https://calendly.com/3iap/intro-call"}>3iap's Calendly</a></li>
                    <li>Or use the form below to send a message</li>
                </ul>
            </LayoutInner>

           <ContactForm/>

        </Layout>
    )
}

export const Page = withStyles(styles)(_Page)
export default Page

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
